window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */ 

try {
    window.$ = window.jQuery = require('jquery');

    require('bootstrap-sass');
    //require('bootstrap');
    require('select2');
    require('select2/dist/js/i18n/el.js');
    window.bootbox = require('bootbox');
    require('parsleyjs');
    require('parsleyjs/dist/i18n/el.js');

    // My javascript
    window.My = require('./my.js');

    // moment
    window.moment = require('moment');
    //require('moment/locale/el.js');

    // tinymce
    require('tinymce');
    require('tinymce/themes/modern/theme');
    require('tinymce/plugins/advlist');
    require('tinymce/plugins/lists');
    require('tinymce/plugins/charmap');
    require('tinymce/plugins/link');
    require('tinymce/plugins/preview');
    require('tinymce/plugins/print');
    require('tinymce/plugins/table');
    require('tinymce/plugins/paste');
    require('tinymce/plugins/template');
    require('tinymce/plugins/autolink');
    require('tinymce/plugins/code');
    require('tinymce/plugins/textcolor');

    // eonasda-bootstrap-datetimepicker
     require('eonasdan-bootstrap-datetimepicker');

    // datatables
    require('datatables.net');
    require('datatables.net-bs');
    require('./datatables/datetime-moment.min.js');
    //require('yadcf/jquery.dataTables.yadcf.js');

    // jquery datepicker
    require('jquery-ui/ui/widgets/datepicker.js');
    require('jquery-ui/ui/i18n/datepicker-el.js');

    // jquery autocomplete
    require('jquery-ui/ui/widgets/autocomplete.js');
    
    // spectrum-colorpicker
    require('spectrum-colorpicker');

    // bootstrap-fileinput
    require('bootstrap-fileinput/js/fileinput.min.js');
    require('bootstrap-fileinput/js/locales/el.js');

    // toastr
    window.toastr = require('toastr');
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"

    };

    
    
} catch (e) {}



/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

